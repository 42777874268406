import * as Sentry from "@sentry/astro";

Sentry.init({
    dsn: "https://df34619e226c21f6e3a0040931430dc1@o4507768658001920.ingest.de.sentry.io/4507769655394384",
    environment: import.meta.env.MODE,
    // TODO: These values are good for BFU Demo testing on 14.08.24. Change prod to 0.1 afterwards.
    sampleRate: import.meta.env.PROD ? 1.0 : 0.1,
    tracesSampleRate: import.meta.env.PROD ? 1.0 : 0.1,
    tracePropagationTargets: [`${import.meta.APP_URL}/api/*`],
    replaysSessionSampleRate: import.meta.env.PROD ? 1.0 : 0,
    replaysOnErrorSampleRate: import.meta.env.PROD ? 1.0 : 0,
    integrations: [
        // Sentry.feedbackIntegration({
        //     colorScheme: "system"
        // }),
        Sentry.replayIntegration({
            maskAllText: true,
            blockAllMedia: true
        }),
        Sentry.browserTracingIntegration({
            beforeStartSpan: (context) => {
                // Normalize the URL path to group transactions, to appear grouped in sentry
                // TODO: check if all urls are represented correctly and if the list is complete
                const normalizedPath = location.pathname
                    .replace(/\/internal\/check\/[^/]+\/configuration/, "/internal/check/<id>/configuration")
                    .replace(/\/internal\/check\/[^/]+\/execution/, "/internal/check/<id>/execution")
                    .replace(/\/internal\/check\/[^/]+\/review/, "/internal/check/<id>/review")
                    .replace(/\/internal\/check\/[^/]+\/oid-import/, "/internal/check/<id>/oid-import")
                    .replace(/\/api\/bfu\/oid-import\/[^/]+\//, "/api/bfu/oid-import/<oid>/")

                return {
                    ...context,
                    name: normalizedPath
                };
            }
        })
    ],
    beforeSend(event) {
        // Check if it is an exception, and if so, show the report dialog
        if (event.exception && event.event_id) {
            Sentry.showReportDialog({ eventId: event.event_id });
        }
        return event;
    }
});
